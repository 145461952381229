import React from "react";

import "./Background.css";

import { colors } from "../../Constants/Color";
import background from "../../Assets/Imgs/background.jpg";

const Background = () => {
	const scrollIntoView = () =>{
		const solutions = document.getElementById("solutions");
		solutions.scrollIntoView({behavior: "smooth"})
	}
	return (
		<div id="background">
			<div className="intro_section">
				<div className="intro">
					<div className="intro_title" style={{ color: colors.primary }}>
						Powem
					</div>
					<div className="intro_text">
						Specialised software development <br />
						for specialised needs. <br />

					</div>
					<div
						className="solutions_btn"
						style={{ border: `solid 2px ${colors.primary}` }}
						onClick={scrollIntoView}
					>
						Solutions
					</div>

				</div>
			</div>

			<div className="image_section">
				<img id="background_image" src={background} alt="background" />
				<div id="credit">
					<span
						style={{ margin: `0 5px` }}
						dangerouslySetInnerHTML={{ __html: `&copy` }}
					></span>

					<a style={{color: colors.primary}} href="http://www.freepik.com" target="_blank">
						Designed by Freepik
					</a>
					{/* ! FOR NOT HAVING OUR BUTTS SUED FOR USING THE IMAGE ! */}
				</div>
			</div>
		</div>
	);
};

export default Background;
