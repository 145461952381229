import React from "react";
import Background from "../Components/Background/Background";
import About from "../Components/About/About";
import Solutions from "../Components/Solutions/Solutions";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";

const Home = () => {
    return(
        <div className="page">
            <Background />
            <About />
            <Solutions />
            <Contact />
            <Footer />
        </div>
    )
};

export default Home;
