import React from "react";
import { colors } from "../../Constants/Color";

import "./Contact.css";

const Contact = () => {
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<div id="contact">
			<div className="header" style={{ color: colors.primary }}>
				Get in touch
			</div>

			<div id="contact_section">
				{/*<div className="image_section" style={{marginRight: window.innerWidth > 900 ? "25px" : 0}}>*/}
				{/*	<iframe*/}
				{/*		id="map"*/}
				{/*		frameBorder="0"*/}
				{/*		style={{ border: "0" }}*/}
				{/*		src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAbxsZn8tzn0Ks7hoFv5x4gvfWyoHarT5g&q=Space+Needle,Seattle+WA"*/}
				{/*	></iframe>*/}
				{/*</div>*/}
				<div className="intro_section">
					<form onSubmit={handleSubmit}>
						<div className="format">
							<div className="input_title">Name: </div>
							<input
								className="input"
								placeholder="Your name..."
								type="text"
								name="name"
								required
							/>
						</div>
						<div className="format">
							<div className="input_title">Email: </div>

							<input
								className="input"
								placeholder="Your email..."
								type="email"
								name="email"
								required
							/>
						</div>
						<div className="format">
							<div className="input_title">Message: </div>

							<textarea
								id="textarea"
								placeholder="What can we do for you?"
							></textarea>
						</div>

						<button
							type="submit"
							id="submit_btn"
							style={{ border: `solid 2px ${colors.primary}` }}
						>
							Submit
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Contact;
