import React from "react";

import "./Modal.css";
import { colors } from "../../Constants/Color";

const Modal = (props) => {
	return (
		<div
			id="modal_container"
			style={{
				display: props.show ? "block" : "none",
				background: colors.lightFade,
				transition: "all ease-in-out 400ms",
			}}
		>
			<div id="content_position">
				<div id="content_container">
					<div id="update_state" onClick={props.updateState.bind(this, false)}>
						{" "}
						{"<-"} go back
					</div>

					<div id="content_title" style={{ color: colors.primary }}>
						WHY US
					</div>
					<div id="content">
						Coming soon..
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
