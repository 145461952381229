import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { colors } from "../../Constants/Color";

import "./Footer.css";

const Footer = () => {
	const [date, setDate] = useState("");
	useEffect(() => {
		const date = () => {
			const date = new Date();
			setDate(date.getFullYear());
		};

		date();
	}, []);
	return (
		<div id="footer">
			<Link to="/">
				<span
					className="intro_title"
					style={{ color: colors.primary, margin: "0 5px" }}
				>
					Powem
				</span>
			</Link>
			<span
				style={{ margin: `0 5px` }}
				dangerouslySetInnerHTML={{ __html: `&copy;` }}
			></span>
			Copyright <span>{date}</span>
		</div>
	);
};

export default Footer;
