import React from "react";

import "./Card.css";

import { colors } from "../../../Constants/Color";

const Card = (props) => {
	const scrollIntoView = () =>{
		const contact = document.getElementById("contact");
		contact.scrollIntoView({behavior: "smooth"})
	}
	return (
		<div className="card">
			<div
				className="card_title"
				style={{
					fontFamily: props.font,
					fontWeight: props.bold ? "700" : "normal",
				}}
			>
				{props.title}
			</div>

			<div className="card_description">{props.description}</div>

			<div className="card_visit_btn">
				{props.contact ? (
					<span style={{ color: colors.primary }} onClick={scrollIntoView}>
						Contact Us
					</span>
				) : (
					<a style={{ color: colors.primary }} href={props.link}>
						Visit {props.title}
					</a>
				)}
			</div>
		</div>
	);
};

export default Card;
