import React from "react";

import "./Solutions.css";

import { colors } from "../../Constants/Color";
import Card from "./Card/Card";

const Solutions = () => {
	return (
		<div id="solutions">
			<div className="header" style={{ color: colors.primary }}>
				Solutions
			</div>

			<div id="projects">
				<Card
					title="Gezako"
					font="Poppins"
					description="A Software tests reporting tool for Spock"
					link="https://gezako.com"
					bold={false}
					contact={false}
				/>
				<Card
					title="PastPaperHub"
					font="Pacifico"
					description="Find and collaborate on Ugandan University pastpapers/exams"
					link="https://pastpaperhub.com"
					bold={true}
					contact={false}
				/>
				<Card
					title="Your Project"
					font="Poppins Bold"
					description="Coming soon... Ready to take off your project? Contact us now"
					link="/#contact"
					bold={false}
					contact={true}
				/>
			</div>
		</div>
	);
};

export default Solutions;
