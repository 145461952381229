import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "./Assets/Fonts/fonts.css";
import "./App.css";

import { colors } from "./Constants/Color";
import Home from "./Pages/Home";

const App = () => {
	const scrollBar = () =>{
		if(colors.primary === "#207eb4"){
			return "app-blue"
		} else{
			return "app-sec"
		}
	}
	return (
		<div id={scrollBar()} style={{background: colors.light}}>
			<Router>
				<Switch>
					<Route path="/" exact component={Home} />
					<Redirect path="*" to="/" />
				</Switch>
			</Router>
		</div>
	);
};

export default App;
