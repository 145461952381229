import React, {useState} from "react";
import { Link } from "react-router-dom";

import "./About.css";

import { colors } from "../../Constants/Color";
import Modal from "../Modal/Modal";

import power from "../../Assets/Imgs/power.jpg";
import rich from "../../Assets/Imgs/rich.jpg";
import derek from "../../Assets/Imgs/derek.jpg";

const About = () => {
	const [modal, setModal] = useState(false);

	const updateState = (data) =>{
		setModal(data)
	}
	return (
		<div id="about">
			<Modal updateState={updateState} show={modal} />
			<div
				id="image_section_about"
			>

				<span className="image_style">
					<img
						id="ceo_image"
						src={power}
						alt="Power Mukisa"
						title="Power is the CEO of the company"
					/>
					<div id="center_tags">
						<span id="ceo_name" style={{ color: colors.primary }}>
							Power Mukisa
						</span>
						<div id="ceo_tag">CEO</div>
					</div>
				</span>

				<span className="image_style">
					<img
						id="ceo_image"
						src={rich}
						alt="Rich"
						title="Rich is a Software Engineer at Powem"
					/>
					<div id="center_tags">
						<span id="ceo_name" style={{ color: colors.primary }}>
							Rich
						</span>
						<div id="ceo_tag">Software Engineer</div>
					</div>
				</span>

				<span className="image_style">
					<img
						id="ceo_image"
						src={derek}
						alt="Derek"
						title="Derek is a Software developer at Powem"
					/>
					<div id="center_tags">
						<span id="ceo_name" style={{ color: colors.primary }}>
							Derek Leiro
						</span>
						<div id="ceo_tag">Software Engineer</div>
					</div>
				</span>

			</div>
			<div className="intro_section">
				<div className="intro">
					<div className="intro_title" style={{ color: colors.primary }}>
						About
					</div>
					<div className="intro_text">
						Powem is a company built by Ugandans who have seen the challenges
						in building technology solutions for the community. We want to build
						better - more relevant Software for the community, and also help
						entrepreneurs who have ideas ranging from Agriculture, health, to all
						major sectors in the community, to see their ideas come to life. We
						offer professional consultation services including Project & Product
						Management, Agile Software Development and Digital Marketing.
						<br />
						<br /> We also offer internally built software solutions in the
						Education and Software Engineering space. Check out our solutions
						below
					</div>
					<div
						className="solutions_btn"
						style={{ border: `solid 2px ${colors.primary}` }}
						onClick={()=> setModal(true)}
					>
						Why us
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
